import React from 'react';

import { css, cx } from '@headout/pixie/css';

const loadingDots = css({
	display: 'flex',
	alignItems: 'center',
	textAlign: 'center',
	width: '100%',
	margin: '3.125rem 0',
});

const dot = css({
	animation: 'loadingDotsAnimation 1.5s infinite ease-in-out',
	backgroundColor: 'core.primary.purps',
	borderRadius: '0.5rem',
	display: 'inline-block',
	height: '0.5rem',
	width: '0.5rem',
	marginRight: '0.25rem',

	'&:nth-child(2)': {
		animationDelay: '0.5s',
	},

	'&:nth-child(3)': {
		animationDelay: '1s',
	},
});

type Props = {
	className?: string;
};

const LoadingDots = (props: Props) => {
	const { className, ...otherProps } = props;
	return (
		<div
			key='loading-dots'
			className={cx(loadingDots, 'core-loading-dots', className)}
			{...otherProps}
		>
			<div className={dot} key='dot-1' />
			<div className={dot} key='dot-2' />
			<div className={dot} key='dot-3' />
		</div>
	);
};

export default LoadingDots;
